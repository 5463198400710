/**
 * To use a Flag in the frontend, add it to the Flags type and the `defaultFlags` object below.
 */

export type Flags = {
  'release-search-experiences-in-help-hub': boolean;
  'release-search-experiences-in-spotlight': boolean;
  'support-ruby-on-rails': boolean;
  'release-themes-v2': boolean;
  'release-authed-zendesk-image-loading': boolean;
  'enable-pendo-open-guide-action': boolean;
  'show-incident-banner': '' | string;
  'show-dev-env-banner': boolean;
  'show-persistent-copilot-cta': boolean;
  'release-audiences-for-command-types': boolean;
  'copilot-v-3': boolean;
  'release-analytics-events-refresh': boolean;
  'hackweek-live-events-table': boolean;
  'release-analytics-v2-qa': boolean;
  'release-preview-v-2': boolean;
  'segment-webhook-destination': boolean;
  'amplitude-audience-integration': boolean;
  'release-copilot-attachments': boolean;
  'release-copilot-impersonate-users': boolean;
  'release-copilot-action-audience': boolean;
  'enable-when-element-appears-trigger': boolean;
  'release-copilot-avatar-cdn': boolean;
  'release-chat-history': boolean;
  'release-zoho-work-drive-integration': boolean;
  'switch-open-help-doc-in-new-tab': boolean;
  'enable-inline-tooltip-options': boolean;
  'release-nudge-goals': boolean;
  'release-capture-custom-events': boolean;
  'release-widget-animations': boolean;
  'release-copilot-fallbacks-v-2': boolean;
  'release-user-property-management': boolean;
  'release-audience-refinement': boolean;
  'release-skilljar-integration': boolean;
  'release-track-metadata-sdk-calls': boolean;
  'release-copilot-analytics-v2': boolean;
  'release-no-code-editor': boolean;
  'release-nudge-arrows': boolean;
  'disable-custom-spotlight-hotkey': boolean;
};

export const defaultFlags: Flags = {
  'release-search-experiences-in-help-hub': false,
  'release-search-experiences-in-spotlight': false,
  'support-ruby-on-rails': false,
  'release-themes-v2': false,
  'release-authed-zendesk-image-loading': false,
  'enable-pendo-open-guide-action': false,
  'show-incident-banner': '',
  'show-dev-env-banner': false,
  'release-audiences-for-command-types': false,
  'copilot-v-3': false,
  'release-analytics-events-refresh': false,
  'hackweek-live-events-table': false,
  'release-analytics-v2-qa': false,
  'release-preview-v-2': false,
  'segment-webhook-destination': false,
  'amplitude-audience-integration': false,
  'show-persistent-copilot-cta': false,
  'release-copilot-attachments': false,
  'release-copilot-impersonate-users': false,
  'release-copilot-action-audience': false,
  'enable-when-element-appears-trigger': false,
  'release-copilot-avatar-cdn': false,
  'release-chat-history': false,
  'release-zoho-work-drive-integration': false,
  'switch-open-help-doc-in-new-tab': false,
  'enable-inline-tooltip-options': false,
  'release-nudge-goals': false,
  'release-capture-custom-events': false,
  'release-widget-animations': false,
  'release-copilot-fallbacks-v-2': false,
  'release-user-property-management': false,
  'release-audience-refinement': false,
  'release-skilljar-integration': false,
  'release-track-metadata-sdk-calls': false,
  'release-copilot-analytics-v2': false,
  'release-no-code-editor': false,
  'release-nudge-arrows': false,
  'disable-custom-spotlight-hotkey': false,
};
