/* React imports */
/* eslint react/prop-types: 0 */

import * as React from 'react';

import { AutoComplete as AntdAutoComplete, AutoCompleteProps as AntdAutoCompleteProps } from 'antd';

export const AutoComplete = (props: AntdAutoCompleteProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <AntdAutoComplete
      {...props}
      onClick={(e: any) => {
        setOpen(!open);
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      onSelect={(val: any, option: any) => {
        if (props.onSelect) {
          props.onSelect(val, option);
        }
        setOpen(false);
      }}
      open={open}
      onBlur={(e) => {
        props.onBlur && props.onBlur(e);
        setOpen(false);
      }}
    >
      {props.children}
    </AntdAutoComplete>
  );
};
