import {
  type HideOptions,
  type OffsetOptions,
  type Placement,
  arrow,
  autoUpdate,
  computePosition,
  hide,
  offset,
  shift,
} from '@floating-ui/dom';

import type { INudgePinStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { getSentry } from '@commandbar/internal/util/sentry';
import {
  getElement,
  fastIsElementVisible as isElementVisible,
  isWithinCommandBar,
} from '@commandbar/internal/util/dom';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';

export type TPin = {
  nudge: INudgeType;
  step: INudgePinStepType;
  targetEl?: Element;
  advanceTriggerEl?: Element;
  isOpenByDefault: boolean;
  isShowingMask: boolean;
  offset: { x: string; y: string };
};

export const BEACON_SIZE = 16;
export const BEACON_INSET = 8;
const MIN_MARGIN = 24;

type PositionPin = (
  pin:
    | TPin
    | {
        nudge: INudgeType;
        step: INudgePinStepType;
        targetEl?: Element;
        offset: { x: string; y: string };
      },
  floatingElement: HTMLDivElement,
  contentElement: HTMLDivElement,
  isOpen: boolean,
  closePin: () => void,
  isAnimatedWidget: boolean,
) => (() => void) | undefined;

const positionPin: PositionPin = (pin, beaconElement, contentElement, isOpen, closePin, isAnimatedWidget) => {
  let targetEl = pin.targetEl;
  let beaconHidden: boolean;

  if (targetEl) {
    const positioningConfig = generatePositioningConfig(targetEl, contentElement, {
      position: pin.step.form_factor.position,
      alignment: pin.step.form_factor.alignment,
      offset: pin.offset,
    });

    const transformOrigin = convertPlacementToTransformOrigin(positioningConfig.content.placement);

    const updateBeaconPosition = async () => {
      if (targetEl) {
        const { x, y, middlewareData } = await computePosition(targetEl, beaconElement, {
          placement: positioningConfig.beacon.placement,
          middleware: [
            offset(positioningConfig.beacon.offset),
            hide({ strategy: 'escaped', ...positioningConfig.beacon.hide }),
          ],
        });
        beaconHidden = !!middlewareData.hide?.escaped;

        Object.assign(beaconElement.style, {
          top: `${y}px`,
          left: `${x}px`,
          visibility: middlewareData.hide?.escaped ? 'hidden' : 'visible',
          opacity: middlewareData.hide?.escaped ? 0 : 1,
        });
      }
    };

    const arrowEl = document.querySelector('.commandbar-nudge-arrow') as HTMLElement | null;

    const updateContentPosition = async () => {
      if (targetEl) {
        const { x, y, middlewareData } = await computePosition(targetEl, contentElement, {
          placement: isMobileDevice() ? 'bottom-start' : positioningConfig.content.placement,
          middleware: [
            offset(positioningConfig.content.offset),
            shift({ padding: MIN_MARGIN }),
            hide(),
            ...(arrowEl ? [arrow({ element: arrowEl })] : []), // Conditionally include the arrow middleware
          ],
        });

        Object.assign(contentElement.style, {
          top: `${y}px`,
          left: `${x}px`,
          visibility: middlewareData.hide?.referenceHidden || beaconHidden || !isOpen ? 'hidden' : 'visible',
          transformOrigin,
          // There was this issue where the pin would show in the top left corner before moving to the actual target
          // We want the pin to be hidden until it's actually pinned to the target. When using visibility alone, it would show before it was ready.
          // Adding that opacity here was a possible fix for that
          //
          // In WidgetAnimations this isn't an issue since we're animating that behavior, so we don't need/shouldn't control the opacity here
          ...(!isAnimatedWidget && {
            opacity: middlewareData.hide?.referenceHidden || beaconHidden || !isOpen ? 0 : 1,
          }),
        });

        // Handle arrow-specific logic if `arrowEl` exists and middlewareData.arrow is present
        if (arrowEl && middlewareData.arrow) {
          const placement = positioningConfig.content.placement;
          arrowEl.classList.add(`pin-arrow-${placement}`);
        }
      }
    };

    const cleanupBeacon = autoUpdate(targetEl, beaconElement, updateBeaconPosition, { animationFrame: true });
    const cleanupContent = autoUpdate(targetEl, contentElement, updateContentPosition, { animationFrame: true });

    const cleanup = () => {
      cleanupBeacon();
      cleanupContent();
      closePin();
      observer.disconnect();
    };

    let removalTimeout: ReturnType<typeof setTimeout> | null = null;

    const hideElementsAndScheduleCleanup = () => {
      beaconElement.style.visibility = 'hidden';
      contentElement.style.visibility = 'hidden';

      if (!removalTimeout) {
        removalTimeout = setTimeout(() => {
          cleanup();
        }, 1000);
      }
    };

    const clearRemovalTimeoutAndUpdatePositions = () => {
      if (removalTimeout) {
        clearTimeout(removalTimeout);
        removalTimeout = null;
      }
      updateBeaconPosition();
      updateContentPosition();
    };

    const handleMutation = (mutation: MutationRecord) => {
      if (
        isWithinCommandBar(mutation.target) ||
        Array.from(mutation.addedNodes).some(isWithinCommandBar) ||
        Array.from(mutation.removedNodes).some(isWithinCommandBar)
      )
        return;

      switch (mutation.type) {
        case 'childList': {
          handleChildListMutation(mutation);
          break;
        }
        case 'attributes': {
          if (isElementVisible(targetEl)) {
            clearRemovalTimeoutAndUpdatePositions();
          } else {
            hideElementsAndScheduleCleanup();
          }
          break;
        }
        default:
          break;
      }
    };

    const handleChildListMutation = (mutation: MutationRecord) => {
      for (const node of mutation.removedNodes) {
        if (targetEl && (node === targetEl || node.contains(targetEl))) {
          targetEl = undefined;
          hideElementsAndScheduleCleanup();
        }
      }

      for (const node of mutation.addedNodes) {
        if (isElementVisible(targetEl) && (node === targetEl || node.contains(targetEl))) {
          clearRemovalTimeoutAndUpdatePositions();
        }
      }
    };

    const observer = new MutationObserver((mutations) => {
      if (!targetEl) {
        targetEl = getElement(pin.step.form_factor.anchor_selector || pin.step.form_factor.anchor);
      }
      mutations.forEach(handleMutation);
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['style', 'class'],
    });

    return () => {
      cleanupBeacon();
      cleanupContent();
      observer.disconnect();
      if (removalTimeout) clearTimeout(removalTimeout);
    };
  }
};

// This function is used to determine if the pin is off screen on the initial render.
// since we don't get a chance to reposition the pin until the next render, we need to check this on mount
export function isElementOffScreen(rect: DOMRect, scrollParent: Element | null) {
  if (scrollParent) {
    const parentRect = scrollParent.getBoundingClientRect();
    return (
      rect.bottom < parentRect.top ||
      rect.top > parentRect.bottom ||
      rect.right < parentRect.left ||
      rect.left > parentRect.right
    );
  }

  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

  return rect.top > viewportHeight || rect.bottom < 0 || rect.left > viewportWidth || rect.right < 0;
}

export const findScrollParent = (element: Element, nudge?: INudgeType): Element | null => {
  if (!(element instanceof Element)) {
    getSentry()?.captureMessage('Invalid element provided to findScrollParent.', 'error', {
      captureContext: {
        tags: {
          product: 'nudges',
        },
        contexts: {
          nudge,
        },
      },
    });
    return null;
  }

  let currentElement;
  const rootNode = element.getRootNode() as Document | ShadowRoot;

  if (rootNode instanceof ShadowRoot) {
    currentElement = rootNode.host;
  } else {
    currentElement = element.parentElement;
  }

  let iterationCount = 0;
  // INFO: This is a safety net to prevent infinite loops
  // I doubt we'll ever get to 1000 iterations but it's better to be safe than sorry
  const maxIterations = 30;

  while (currentElement && iterationCount < maxIterations && currentElement !== document.body) {
    const overflowY = window.getComputedStyle(currentElement).overflowY;
    const overflowX = window.getComputedStyle(currentElement).overflowX;
    const isScrollable =
      (overflowX !== 'visible' && overflowX !== 'hidden') || (overflowY !== 'visible' && overflowY !== 'hidden');

    const hasOverflowingContent =
      currentElement.scrollHeight > currentElement.clientHeight ||
      currentElement.scrollWidth > currentElement.clientWidth;

    if (isScrollable && hasOverflowingContent) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
    iterationCount++;
  }

  if (iterationCount >= maxIterations) {
    getSentry()?.captureMessage('findScrollParent exceeded maximum iteration count', 'error', {
      captureContext: {
        tags: {
          product: 'nudges',
        },
        contexts: {
          nudge,
        },
      },
    });
    return null;
  }

  return null;
};

export const getElementCenter = (rect: DOMRect): { x: number; y: number } => ({
  x: rect.left + rect.width / 2,
  y: rect.top + rect.height / 2,
});

export const _calculateNewScrollPosition = (
  elementCenter: { x: number; y: number },
  scrollContainer: Element,
): { x: number; y: number } => ({
  x: elementCenter.x - scrollContainer.clientWidth / 2 + scrollContainer.scrollLeft,
  y: elementCenter.y - scrollContainer.clientHeight / 2 + scrollContainer.scrollTop,
});

export const calculateNewScrollPosition = (
  elementCenter: { x: number; y: number },
  scrollContainer: Element,
  options?: Partial<{ isScrollableContainer: boolean }>,
): { x: number; y: number } => {
  const containerRect = scrollContainer.getBoundingClientRect();

  return {
    x:
      elementCenter.x -
      (options?.isScrollableContainer ? containerRect.left : 0) -
      scrollContainer.clientWidth / 2 +
      scrollContainer.scrollLeft,
    y:
      elementCenter.y -
      (options?.isScrollableContainer ? containerRect.top : 0) -
      scrollContainer.clientHeight / 2 +
      scrollContainer.scrollTop,
  };
};

export const scrollToElement = (element: Element | Window, options: ScrollToOptions): void => {
  try {
    element.scrollTo(options);
  } catch (error) {
    getSentry()?.captureException(error);
  }
};

const getDistanceFromTop = (element: Element) =>
  element.getBoundingClientRect().top + window.scrollY || document.documentElement.scrollTop;

const getDistanceFromBottom = (element: Element) => {
  const { top, height } = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const elementBottom = top + scrollTop + height;
  const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.offsetHeight);

  return documentHeight - elementBottom;
};

const getDistanceFromLeft = (element: Element) =>
  element.getBoundingClientRect().left + window.scrollX || document.documentElement.scrollLeft;

const getDistanceFromRight = (element: Element) => {
  const { left, width } = element.getBoundingClientRect();
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  const elementRight = left + scrollLeft + width;
  const documentWidth = Math.max(document.body.scrollWidth, document.documentElement.offsetWidth);

  return documentWidth - elementRight;
};

const cyclePlacementsFromTop = (
  targetElement: Element,
  neededSpace: { x: number; y: number },
): Exclude<INudgePinStepType['form_factor']['position'], 'auto'> => {
  const availableSpaceTop = getDistanceFromTop(targetElement);
  if (neededSpace.y <= availableSpaceTop) {
    return 'top';
  }

  const availableSpaceBottom = getDistanceFromBottom(targetElement);
  if (neededSpace.y <= availableSpaceBottom) {
    return 'bottom';
  }

  const availableSpaceRight = getDistanceFromRight(targetElement);
  if (neededSpace.x <= availableSpaceRight) {
    return 'right';
  }

  return 'left';
};

const cyclePlacementsFromBottom = (
  targetElement: Element,
  neededSpace: { x: number; y: number },
): Exclude<INudgePinStepType['form_factor']['position'], 'auto'> => {
  const availableSpaceBottom = getDistanceFromBottom(targetElement);
  if (neededSpace.y <= availableSpaceBottom) {
    return 'bottom';
  }

  const availableSpaceTop = getDistanceFromTop(targetElement);
  if (neededSpace.y <= availableSpaceTop) {
    return 'top';
  }

  const availableSpaceRight = getDistanceFromRight(targetElement);
  if (neededSpace.x <= availableSpaceRight) {
    return 'right';
  }

  return 'left';
};

const cyclePlacementsFromLeft = (
  targetElement: Element,
  neededSpace: { x: number; y: number },
): Exclude<INudgePinStepType['form_factor']['position'], 'auto'> => {
  const availableSpaceLeft = getDistanceFromLeft(targetElement);
  if (neededSpace.x <= availableSpaceLeft) {
    return 'left';
  }

  const availableSpaceRight = getDistanceFromRight(targetElement);
  if (neededSpace.x <= availableSpaceRight) {
    return 'right';
  }

  const availableSpaceTop = getDistanceFromTop(targetElement);
  if (neededSpace.y <= availableSpaceTop) {
    return 'top';
  }

  return 'bottom';
};

const cyclePlacementsFromRight = (
  targetElement: Element,
  neededSpace: { x: number; y: number },
): Exclude<INudgePinStepType['form_factor']['position'], 'auto'> => {
  const availableSpaceRight = getDistanceFromRight(targetElement);
  if (neededSpace.x <= availableSpaceRight) {
    return 'right';
  }

  const availableSpaceLeft = getDistanceFromLeft(targetElement);
  if (neededSpace.x <= availableSpaceLeft) {
    return 'left';
  }

  const availableSpaceTop = getDistanceFromTop(targetElement);
  if (neededSpace.y <= availableSpaceTop) {
    return 'top';
  }

  return 'bottom';
};

const computeInitialPinPlacement = (
  preferredPlacement: INudgePinStepType['form_factor']['position'],
  targetElement: Element,
  neededSpace: { x: number; y: number },
): Exclude<INudgePinStepType['form_factor']['position'], 'auto'> => {
  switch (preferredPlacement) {
    case 'left': {
      return cyclePlacementsFromLeft(targetElement, neededSpace);
    }
    case 'right': {
      return cyclePlacementsFromRight(targetElement, neededSpace);
    }
    case 'top': {
      return cyclePlacementsFromTop(targetElement, neededSpace);
    }
    case 'bottom': {
      return cyclePlacementsFromBottom(targetElement, neededSpace);
    }
    default: {
      const viewportCenterX = window.innerWidth / 2;
      const { x: elementCenterX } = getElementCenter(targetElement.getBoundingClientRect());

      if (elementCenterX > viewportCenterX) {
        return 'left';
      }

      return 'right';
    }
  }
};

const generatePositioningConfig = (
  targetElement: Element,
  contentElement: HTMLDivElement,
  pinProperties: {
    position: INudgePinStepType['form_factor']['position'];
    alignment: INudgePinStepType['form_factor']['alignment'];
    offset: NonNullable<INudgePinStepType['form_factor']['offset']>;
  },
): {
  beacon: { placement: Placement; offset: OffsetOptions; hide?: HideOptions };
  content: { placement: Placement; offset: OffsetOptions };
} => {
  const { width: contentWidth, height: contentHeight } = contentElement.getBoundingClientRect();
  const { height: targetHeight } = targetElement.getBoundingClientRect();
  const neededSpace = {
    x: contentWidth + MIN_MARGIN + calcOffsetFromInput(pinProperties.offset.x) + BEACON_INSET + BEACON_SIZE,
    y: contentHeight + MIN_MARGIN + calcOffsetFromInput(pinProperties.offset.y) + BEACON_INSET + BEACON_SIZE,
  };
  const position = computeInitialPinPlacement(pinProperties.position, targetElement, neededSpace);
  const { alignment, offset } = pinProperties;

  switch (position) {
    case 'top': {
      const hide = {
        padding: {
          top: -calcOffsetFromInput(offset.y),
          right: -calcOffsetFromInput(offset.x),
          left: calcOffsetFromInput(offset.x),
          bottom: calcOffsetFromInput(offset.y),
        },
      };

      switch (alignment) {
        case 'left': {
          return {
            beacon: {
              placement: 'top-start',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: -BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'top-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                alignmentAxis: calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
        case 'right': {
          return {
            beacon: {
              placement: 'top-end',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'top-end',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: contentWidth + calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
        case 'center':
        default: {
          return {
            beacon: {
              placement: 'top',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'top',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: contentWidth / 2 + calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
      }
    }
    case 'bottom': {
      const hide = {
        padding: {
          top: calcOffsetFromInput(offset.y),
          right: -calcOffsetFromInput(offset.x),
          left: calcOffsetFromInput(offset.x),
          bottom: -calcOffsetFromInput(offset.y),
        },
      };

      switch (alignment) {
        case 'left': {
          return {
            beacon: {
              placement: 'bottom-start',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: -BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'bottom-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
        case 'right': {
          return {
            beacon: {
              placement: 'bottom-end',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'bottom-end',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: contentWidth + calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
        case 'center':
        default: {
          return {
            beacon: {
              placement: 'bottom',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'bottom',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                crossAxis: contentWidth / 2 + calcOffsetFromInput(offset?.x ?? '0'),
              },
            },
          };
        }
      }
    }
    case 'right': {
      const hide = {
        padding: {
          top: -calcOffsetFromInput(offset.y),
          right: -calcOffsetFromInput(offset.x),
          left: calcOffsetFromInput(offset.x),
          bottom: calcOffsetFromInput(offset.y),
        },
      };

      switch (alignment) {
        case 'center': {
          return {
            beacon: {
              placement: 'right',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                crossAxis: -calcOffsetFromInput(offset?.y ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'right-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: targetHeight / 2 - calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
        case 'bottom': {
          return {
            beacon: {
              placement: 'bottom-end',
              offset: {
                mainAxis: -BEACON_INSET - calcOffsetFromInput(offset?.y ?? '0'),
                alignmentAxis: -BEACON_INSET - calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'right-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: targetHeight - calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
        case 'top':
        default: {
          return {
            beacon: {
              placement: 'top-end',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                alignmentAxis: -BEACON_INSET - calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'right-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: -calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
      }
    }
    case 'left':
    default: {
      const hide = {
        padding: {
          top: -calcOffsetFromInput(offset.y),
          right: calcOffsetFromInput(offset.x),
          left: -calcOffsetFromInput(offset.x),
          bottom: calcOffsetFromInput(offset.y),
        },
      };

      switch (alignment) {
        case 'center': {
          return {
            beacon: {
              placement: 'left',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                crossAxis: -calcOffsetFromInput(offset?.y ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'left-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: targetHeight / 2 - calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
        case 'bottom': {
          return {
            beacon: {
              placement: 'bottom-start',
              offset: {
                mainAxis: -BEACON_INSET - calcOffsetFromInput(offset?.y ?? '0'),
                alignmentAxis: -BEACON_INSET - calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'left-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: targetHeight - calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
        case 'top':
        default: {
          return {
            beacon: {
              placement: 'top-start',
              offset: {
                mainAxis: -BEACON_INSET + calcOffsetFromInput(offset?.y ?? '0'),
                alignmentAxis: -BEACON_INSET - calcOffsetFromInput(offset?.x ?? '0'),
              },
              hide,
            },
            content: {
              placement: 'left-start',
              offset: {
                mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(offset?.x ?? '0'),
                alignmentAxis: -calcOffsetFromInput(offset?.y ?? '0'),
              },
            },
          };
        }
      }
    }
  }
};

export const convertPlacementToTransformOrigin = (placement: Placement) => {
  switch (placement) {
    case 'top':
      return 'bottom center';
    case 'bottom':
      return 'top center';
    case 'left':
      return 'right center';
    case 'right':
      return 'left center';
    case 'top-start':
      return 'bottom left';
    case 'top-end':
      return 'bottom right';
    case 'bottom-start':
      return 'top left';
    case 'bottom-end':
      return 'top right';
    case 'left-start':
      return 'right top';
    case 'left-end':
      return 'right bottom';
    case 'right-start':
      return 'left top';
    case 'right-end':
      return 'left bottom';
    default:
      return 'center';
  }
};

export default positionPin;
