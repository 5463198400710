import * as t from 'io-ts';

import { DateFilter, CardDataV, AdminFilter, StackedBarChartDataV, BarChartDataV } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsCopilotDataV = t.type({
  stacked_bar_chart_data: StackedBarChartDataV,
  total_opens: CardDataV,
  total_fallbacks: CardDataV,
  total_handoffs: CardDataV,
  success_rate: CardDataV,
  handoff_rate: CardDataV,
  handle_time: CardDataV,
  unique_users: CardDataV,
  opens_bar_chart: BarChartDataV,
});

export type AnalyticsCopilotData = t.TypeOf<typeof AnalyticsCopilotDataV>;

export type AnalyticsCopilotParams = DateFilter & AdminFilter;
export type CopilotDownloadTypes = 'overview' | 'history';

export class AnalyticsCopilot {
  private static controller = new AbortController();

  private static cancelRequest() {
    AnalyticsCopilot.controller.abort();
    AnalyticsCopilot.controller = new AbortController();
  }

  public static read = (params: AnalyticsCopilotParams) => {
    AnalyticsCopilot.cancelRequest();

    return read(AnalyticsCopilotDataV, `analytics/v2/copilot`, { signal: AnalyticsCopilot.controller.signal })(params);
  };
  public static download = async (type: CopilotDownloadTypes, params: DateFilter): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`ml/chats/export/${type}/${queryString}`);
  };
}
