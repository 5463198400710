import { DefaultOptionType } from 'antd/lib/select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoComplete } from '@commandbar/design-system/components/antd';

interface GenericAutoCompleteProps {
  options: Array<{ label: string; value: string }>;
  onChange: (newValue: string) => void;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: React.FocusEventHandler;
  allowArbitraryValue?: boolean;
}

export const GenericAutoComplete = ({
  options,
  onChange,
  value,
  disabled = false,
  placeholder = 'field',
  onBlur,
  allowArbitraryValue = false,
}: GenericAutoCompleteProps) => {
  const [searchValue, setSearchValue] = useState<string>(value || '');
  const [filteredOptions, setFilteredOptions] = useState<DefaultOptionType[]>(options);

  const filter = useCallback(
    (searchText: string) => {
      const filtered = options.filter((option) => option.label?.includes(searchText));
      setFilteredOptions(filtered.length > 0 ? filtered : options);
    },
    [options],
  );

  useEffect(() => {
    filter(searchValue);
  }, [filter, searchValue]);

  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  const optionsWithSearch = useMemo(() => {
    return allowArbitraryValue && searchValue && !options.find((option) => option.label === searchValue)
      ? [
          {
            label: searchValue,
            value: searchValue,
          },
          ...filteredOptions,
        ]
      : options;
  }, [searchValue, filteredOptions, options]);

  const onSearch = useCallback(
    (searchText: string) => {
      const trimmedSearchText = searchText.trim();
      setSearchValue(trimmedSearchText);
      filter(trimmedSearchText);
    },
    [filter],
  );

  const handleOnChange = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <AutoComplete
      style={{ width: '100%', minWidth: 80 }}
      size="small"
      options={optionsWithSearch}
      disabled={disabled}
      dropdownStyle={{ minWidth: 200 }}
      onSearch={onSearch}
      filterOption={false}
      onChange={(value) => handleOnChange(value)}
      placeholder={placeholder}
      onBlur={(e: any) => {
        handleOnChange(e.target.value.trim());
        onBlur && onBlur(e);
      }}
      value={searchValue}
    />
  );
};
