import { interpret } from 'xstate';
import { ref } from 'valtio';
import { hideAllMatchingToasts, hideToast } from 'shared/util/hooks/useToast';
import dayjs from 'dayjs';

import Logger from '@commandbar/internal/util/Logger';
import { updateEndUserStore } from 'shared/store/end-user/actions';
import { getElement } from '@commandbar/internal/util/dom';
import * as GlobalActions from 'shared/store/global-actions';
import {
  MAX_RENDERED_NUDGES,
  getNudgeDataFromUserStore,
  getNudgeServiceSnapshot,
  getDebugService,
  passesFlip,
  passesFrequencyCondition,
  passesGlobalLimit,
  passesMaxRenderedNudges,
  passesPageConditions,
  passesPinnedElement,
  passesStatus,
  getNudgeService,
  getDebuggedNudge,
  getAllNudges,
  isNoGlobalLimitFormFactorStep,
  passesSnoozedConditions,
} from './selectors';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import { RenderMode, renderNudge } from '../components/RenderNudge';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import NudgeManagerMachine from './nudgeManagerMachine';
import { getSentry } from '@commandbar/internal/util/sentry';
import * as ChecklistServiceActions from 'products/checklists/service-actions';
import type { CBStore } from 'shared/store/global-store';
import type { INudgeButtonAction, INudgeType } from '@commandbar/internal/middleware/types';
import type { NudgeInteractions } from '@cb/types/entities/endUser';
import type { TriggerEvent, TriggerEventOverrides } from './nudgeManagerMachine';
import { setupAndTriggerSmartTriggers } from './smartActions';
import { passesAudienceConditions } from 'shared/services/targeting/audience';
import { ShareLinkType, deconstructShareLink } from '@commandbar/internal/client/share_links';
import { ExperienceState } from '@commandbar/internal/client/extension/shared';
import set from 'lodash/set';
import { hideAllMatchingBanners, hideBanner } from '../components/Banner/BannerContext';
import { getSDK } from '@commandbar/internal/client/globals';
import { decide } from '@commandbar/commandbar/shared/services/end-users/decide';
import { _configuration, _userProperties } from '@commandbar/internal/client/symbols';
import {
  generateTriggerableEntityId,
  isNudge,
  TriggerableEntity,
  TriggerableEntityType,
} from '@commandbar/internal/middleware/helpers/pushTrigger';

const shouldDebugNudges = !!LocalStorage.get('debug:nudges', false);

export const initNudges = (_: CBStore, nudges: INudgeType[]) => {
  if (!_.products.includes('nudges')) return;

  if (_.nudgeManager) {
    _.nudgeManager.send({ type: 'REFRESH_FROM_CONFIG', nudges });
    return;
  }

  const service = interpret(NudgeManagerMachine(_, nudges), {
    devTools: shouldDebugNudges,
  });
  _.nudgeManager = ref(service);
  _.nudgeManager.start().onTransition((state) => {
    if (shouldDebugNudges) Logger.debug(`State transition: `, state);
  });

  setupAndTriggerElementAppearNudges(_, nudges);
  setupAndTriggerSmartTriggers(_, nudges);
  triggerSharelinkNudges(_, nudges);
  sendIndirectTrigger(_, { type: 'when_conditions_pass' });
};

export const sendIndirectTrigger = (
  _: CBStore,
  trigger: TriggerEvent['trigger'],
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  const simulatedNudge = getDebuggedNudge(_);
  if (simulatedNudge) {
    sendDirectedTrigger(_, simulatedNudge, trigger, overrides, properties);
  } else {
    _.nudgeManager?.send({
      type: 'TRIGGER',
      trigger,
      overrides,
      properties,
    });
  }
};

const triggerSharelinkNudges = (_: CBStore, nudges: INudgeType[]) => {
  const deconstructedShareLink = deconstructShareLink(_.organization?.share_link_param || 'cb-eid', _.location.search);
  if (deconstructedShareLink) {
    if (deconstructedShareLink?.type === ShareLinkType.NUDGE) {
      const nudge = nudges.find((n) => n.id === deconstructedShareLink.id);
      if (nudge) {
        GlobalActions.activatePushExperience(_, nudge, 'nudge');
      }
    }
  }
};

/*
 * This function is used to force trigger a specific nudge.
 * By default, triggers will go out to all nudges and will be rejected by nudges that don't have a matching trigger.
 * This function will override the trigger match check and only trigger the nudge that matches the nudgeId and is used
 * for things like triggering nudges from a share link, bar, or trigger nudge actions.
 */
export const forceTriggerSingleNudge = (
  _: CBStore,
  nudge: INudgeType,
  overrides?: TriggerEvent['overrides'],
  source?: TriggerEvent['source'],
) => {
  const defaultOverrides = {
    // allow admins to trigger draft nudges
    status: _.isAdmin,
    // allow admins to trigger nudge outside simulate mode
    admin: true,
    // don't check page conditions
    page: true,
    // don't check audience conditions
    audience: true,
    // don't check frequency
    frequency: true,
    // don't check that conditions have changed since last trigger
    flip: true,
    // don't check global limit
    globalLimit: true,
    // always start tours at first step even if they've been seen before
    stepIndex: 0,
  };

  _.nudgeManager?.send({
    type: 'TRIGGER',
    trigger: nudge.trigger,
    nudgeId: nudge.id,
    overrides: {
      ...defaultOverrides,
      ...overrides,
    },
    source,
  });
};

/*
 * This function is used to possibly trigger a specific nudge.
 * This is used when you want to send a trigger event to a specific nudge, but you don't want to force it to show.
 */
export const sendDirectedTrigger = (
  _: CBStore,
  nudge: INudgeType,
  trigger: INudgeType['trigger'],
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
  source?: TriggerEvent['source'],
) => {
  _.nudgeManager?.send({
    type: 'TRIGGER',
    nudgeId: nudge.id,
    trigger,
    overrides,
    properties,
    source,
  });
};

const setupAndTriggerElementAppearNudges = (
  _: CBStore,
  nudges: Array<INudgeType>,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  for (const nudge of nudges) {
    if (nudge.trigger.type === 'when_element_appears') {
      const selector = nudge.trigger.meta.selector;
      _.triggerableSelectors.push(selector);

      if (getElement(selector)) {
        sendIndirectTrigger(_, { type: 'when_element_appears', meta: { selector } }, overrides, properties);
      }
    }
  }
};

export const cleanupElementAppearNudgeTriggers = (_: CBStore) => {
  _.triggerableSelectors = [];
};

const refreshDecideResult = async (_: CBStore, options: Partial<{ isAdmin: boolean }>) => {
  const sdk = getSDK();
  const decideResult = await decide(sdk[_configuration].uuid, _.endUser?.slug, {
    properties: sdk[_userProperties],
    envOverride: _.envOverride,
    env: _.env,
    version: sdk[_configuration].version,
    is_admin: !!options.isAdmin,
  });

  if (decideResult) {
    _.decide = decideResult;
  }
};

export const startDebugSession = async (
  _: CBStore,
  nudge: INudgeType,
  source?: TriggerEvent['source'],
  options: Partial<{ toStepIndex: number; refreshDecide: boolean }> = { refreshDecide: true },
) => {
  if (options.refreshDecide) {
    await refreshDecideResult(_, { isAdmin: true });
  }

  _.nudgeManager?.send({ type: 'START_DEBUG', nudge });

  const overrides: TriggerEventOverrides = {
    stepIndex: options.toStepIndex,
  };

  setupAndTriggerElementAppearNudges(_, getAllNudges(_), overrides, undefined);
  setupAndTriggerSmartTriggers(_, getAllNudges(_), overrides, undefined);
  setupTimedTriggers(_, [nudge], overrides, undefined);

  /*
   * This is similiar to how we initialize nudges from initNudges.
   * In regular mode, we send out some initial triggers to nudges to see if they should be shown. This is done to show "Immediate" nudges.
   *
   * In debug mode, we want all nudges to be triggerable, but we only want to give the simulated nudge the oppurtunity to show immediately.
   * This prevents another "Immediate" nudge from showing when we're trying to debug a specific nudge.
   */
  sendDirectedTrigger(_, nudge, { type: 'when_conditions_pass' }, overrides, undefined, source);
  sendDirectedTrigger(
    _,
    nudge,
    { type: 'when_page_reached', meta: { url: _.location.href } },
    overrides,
    undefined,
    source,
  );
};

export const restartDebugSession = (
  _: CBStore,
  options: { resetToOriginalDebugNudge?: boolean; toStepIndex?: number } = { resetToOriginalDebugNudge: true },
) => {
  const debugNudge = getDebuggedNudge(_, { getOriginal: !!options.resetToOriginalDebugNudge });

  if (debugNudge) {
    stopDebugSession(_, { reopenEditor: false, refreshDecide: false });

    // INFO: ensure the change in _.nudgeDebugToolBar.visible is captured in the toolbar
    // this makes sure we show the intro animation again
    setTimeout(() => {
      startDebugSession(_, debugNudge, undefined, { toStepIndex: options.toStepIndex, refreshDecide: false });
    }, 50);
  }
};

export const stopDebugSession = async (
  _: CBStore,
  options: { reopenEditor?: boolean; refreshDecide?: boolean } = { reopenEditor: true, refreshDecide: true },
) => {
  const debugNudge = getDebuggedNudge(_);

  if (debugNudge) {
    if (options.refreshDecide) {
      await refreshDecideResult(_, { isAdmin: false });
    }

    _.nudgeManager?.send({ type: 'STOP_DEBUG', reopenEditor: options.reopenEditor });
  }
};

export const showStepMock = (
  _: CBStore,
  nudge: INudgeType,
  stepIndex?: number,
  options?: Partial<{ forceOpen: boolean; anchorOverride: string }>,
) => {
  renderNudge(_, nudge, stepIndex, {
    renderMode: RenderMode.MOCK,
    forceOpen: options?.forceOpen,
    anchorOverride: options?.anchorOverride,
  });
};

export const closeAllNudgeMocks = (_: CBStore) => {
  hideAllMatchingToasts(/-mock$/);
  hideAllMatchingBanners(/-mock$/);

  if (_.currentModalNudge?.renderMode === RenderMode.MOCK) {
    _.currentModalNudge = null;
  }
};

export const closeStep = (_: CBStore, nudge: INudgeType, stepIndex: number) => {
  const step = getNudgeStep(nudge, stepIndex);

  if (step?.form_factor.type === 'modal' && _.currentModalNudge?.renderMode !== RenderMode.MOCK) {
    _.currentModalNudge = null;
  } else {
    hideToast(`${nudge.id}-${String(step?.id)}`);
    hideBanner(`${nudge.id}-${String(step?.id)}`);
  }
};

export const closeNudgeMock = (_: CBStore, nudge: INudgeType) => {
  for (let i = 0; i < nudge.steps.length; i++) {
    const step = getNudgeStep(nudge, i);

    if (_.currentModalNudge?.renderMode === RenderMode.MOCK) {
      _.currentModalNudge = null;
    }
    hideToast(`${nudge.id}-${String(step?.id)}-mock`);
    hideBanner(`commandbar-banner-${nudge.id}-${String(step?.id)}-mock`);
  }
};

export const dismissNudge = (_: CBStore, nudge: INudgeType, renderMode: RenderMode) => {
  if (renderMode === RenderMode.MOCK) {
    closeNudgeMock(_, nudge);
  } else {
    const service = getNudgeService(_, nudge.id);
    service?.send('DISMISS');
  }
};

export const closeNudge = (_: CBStore, nudge: INudgeType, renderMode: RenderMode) => {
  if (renderMode === RenderMode.MOCK) {
    closeNudgeMock(_, nudge);
  } else {
    const service = getNudgeService(_, nudge.id);
    service?.send('CLOSE');
  }
};

export const dismissNudgeTemporarily = (
  _: CBStore,
  options: { nudge: INudgeType; stepIndex?: number; anchorOverride?: string; renderMode: RenderMode },
) => {
  if (options.renderMode === RenderMode.MOCK) {
    closeNudgeMock(_, options.nudge);

    setTimeout(() => {
      showStepMock(_, options.nudge, options.stepIndex, {
        forceOpen: true,
        anchorOverride: options.anchorOverride,
      });
    }, 1500);
  }
};

export const continueNudge = (_: CBStore, nudge: INudgeType) => {
  const service = getDebugService(_) ?? getNudgeService(_, nudge.id);
  service?.send('ADVANCE');
};

export const execStepAction = (
  _: CBStore,
  nudge: INudgeType,
  stepIndex: number,
  action?: INudgeButtonAction | null,
) => {
  if (!action) return;

  if (action?.type === 'nudge') {
    resetNudge(_, nudge.id, {
      step: stepIndex + 1,
      dismiss: !isNoGlobalLimitFormFactorStep(nudge, stepIndex),
    });
  }

  GlobalActions.executeAction(_, action, undefined, {}, { type: 'nudge', id: nudge.id });
};

export const saveProgressToEndUserStore = (
  _: CBStore,
  {
    nudge,
    stepIndex,
    stepIndexStack,
    nudgeInteracted,
    nudgeCompleted,
    nudgeDismissed,
    addSeenTime,
    snoozed,
  }: {
    nudge: INudgeType;
    stepIndex: number;
    stepIndexStack?: number[];
    nudgeInteracted?: boolean;
    addSeenTime?: boolean;
    snoozed?: INudgeType['snooze_duration'];
    nudgeCompleted?: boolean;
    nudgeDismissed?: boolean;
  },
) => {
  const nudgeContext = getNudgeDataFromUserStore(_, nudge.id);

  const alreadySeen = nudgeContext?.nudgeSeen;
  const alreadyCompleted = nudgeContext?.nudgeCompleted;
  const alreadyDismissed = nudgeContext?.nudgeDismissed;
  const seenTs = nudgeContext?.seenTs;
  const nudgeCompletedTs = nudgeContext?.nudgeCompletedTs;
  const nudgeDismissedTs = nudgeContext?.nudgeDismissedTs;

  const updatedContext: NudgeInteractions = {
    [Number(nudge.id)]: {
      ...nudgeContext,
      currentStep: stepIndex,
      stepIndexStack,
      nudgeSeen: true,
      nudgeInteracted: !snoozed && (alreadySeen || nudgeInteracted), // if they snoozed a nudge, pretend we didn't interact w/ it (rate limit handling)
      seenTs: addSeenTime ? [...(seenTs ?? []), Date.now()] : snoozed ? seenTs?.slice(0, -1) : seenTs, // if they snoozed a nudge, pretend we didn't see it (rate limit handling)
      snoozedUntilTs: snoozed ? dayjs().add(snoozed.value, snoozed.interval).valueOf() : undefined,
      nudgeCompleted: alreadyCompleted || nudgeCompleted,
      nudgeDismissed: alreadyDismissed || nudgeDismissed,
      nudgeCompletedTs: nudgeCompleted ? [...(nudgeCompletedTs ?? []), Date.now()] : nudgeCompletedTs,
      nudgeDismissedTs: nudgeDismissed ? [...(nudgeDismissedTs ?? []), Date.now()] : nudgeDismissedTs,
    },
  };

  try {
    updateEndUserStore(_, updatedContext, 'nudges_interactions');
  } catch (e) {
    getSentry()?.captureException(e, {
      captureContext: {
        tags: {
          product: 'nudges',
        },
      },
    });
    Logger.error('unable to save', e);
  }
};

export const clearNudgeData = (_: CBStore, nudge: INudgeType) => {
  const id = Number(nudge.id);

  clearNudgeDataById(_, id);
};

export const resetNudge = (_: CBStore, id: INudgeType['id'], options?: Partial<{ step: number; dismiss: boolean }>) => {
  const service = getDebugService(_) ?? getNudgeService(_, id);

  if (service) {
    if (options?.dismiss) {
      service.send('DISMISS');
    }

    service.send({
      type: 'RESET_STATE',
      step: options?.step ?? 0,
    });
  }

  clearNudgeDataById(_, Number(id), options?.step);
};

export const clearNudgeDataById = (_: CBStore, id: number, step?: number) => {
  const allNudgeData = { ..._.endUserStore.data.nudges_interactions } || {};

  if (step !== undefined && step > 0) {
    allNudgeData[id].currentStep = step;
  } else {
    allNudgeData[id] = {};
  }

  updateEndUserStore(_, allNudgeData, 'nudges_interactions');
};

type Checks = Record<string, { result: boolean; explanation: string; detail?: Record<string, unknown> }>;

export const getDebugSnapshot = async (_: CBStore, nudge: INudgeType, stepIndex?: number) => {
  const globalChecks: Checks = {
    maxNudgesRendered: {
      result: passesMaxRenderedNudges(_),
      explanation: `The maximum number of nudges that can be rendered simultaneously is: ${MAX_RENDERED_NUDGES}.`,
    },
    globalLimit: {
      result: passesGlobalLimit(_),
      explanation: 'The limit for nudges shown in this period has been met.',
      detail: {
        limit: _.organization?.nudge_rate_limit,
        period: _.organization?.nudge_rate_period,
      },
    },
  };

  const nudgeChecks: Checks = {
    status: { result: passesStatus(nudge), explanation: 'Nudge has not been published.' },
    frequency: {
      result: passesFrequencyCondition(_, nudge),
      explanation: 'Nudge has been seen the maximum number of times.',
      detail: {
        frequency: nudge.frequency_limit,
      },
    },
    audience: {
      result: passesAudienceConditions(_, 'nudges', nudge),
      explanation: 'Booted user is not targeted by this nudge.',
      detail: {
        audience: nudge.audience,
      },
    },
    page: {
      result: passesPageConditions(_, nudge),
      explanation: 'Nudge is not shown on this page.',
      detail: {
        page: nudge.show_expression,
      },
    },
    snooze: {
      result: passesSnoozedConditions(_, nudge),
      explanation: 'Nudge is snoozed.',
      detail: {
        snoozable: nudge.snoozable,
        snoozableOnAllSteps: nudge.snoozable_on_all_steps,
        snoozeDuration: nudge.snooze_duration,
      },
    },
  };

  const nudgeServiceSnapshot = getNudgeServiceSnapshot(_, nudge.id)?.context;
  const currentStep = stepIndex ?? nudgeServiceSnapshot?.stepIndex ?? 0;

  const step = nudgeServiceSnapshot ? getNudgeStep(nudgeServiceSnapshot.nudge, currentStep) : undefined;

  const stepChecks: Checks = {
    element: {
      result: passesPinnedElement(nudge, currentStep),
      explanation: 'Pinned element is not visible on the page.',
      detail: {
        element: step?.form_factor.type === 'pin' && step?.form_factor.anchor,
      },
    },
  };

  const flipCheck: Checks = nudgeServiceSnapshot
    ? {
        flip: {
          result: passesFlip(nudgeServiceSnapshot.prevPassedConditions, nudgeServiceSnapshot.triggerEvent, step),
          explanation: 'Nudge was shown and conditions have not changed.',
          detail: {
            prevPassedConditions: nudgeServiceSnapshot.prevPassedConditions,
          },
        },
      }
    : {};

  const snapShot: Checks = {
    ...globalChecks,
    ...nudgeChecks,
    ...stepChecks,
    ...flipCheck,
  };

  return {
    willRenderIfTriggered: Object.values(snapShot).every(({ result }) => result),
    checks: Object.fromEntries(
      Object.entries(snapShot).map(([key, value]) => [
        key,
        {
          ...value,
          result: value.result ? 'PASS' : 'FAIL',
        },
      ]),
    ),
    trigger: nudgeServiceSnapshot?.nudge.trigger,
    nudge: nudgeServiceSnapshot?.nudge,
    mostRecentTrigger: nudgeServiceSnapshot?.triggerEvent?.trigger,
  };
};

export const updateNudgeStepForPreview = (
  _: CBStore,
  nudge: INudgeType,
  stepIndex: number,
  field: ExperienceState['field'],
  value: string,
) => {
  let newNudge = { ...nudge };
  newNudge = set(newNudge, `steps.${stepIndex}.${field}`, value);

  _.nudgeManager?.send({ type: 'REFRESH_SIMULATED_NUDGE', nudge: newNudge });
  restartDebugSession(_, { resetToOriginalDebugNudge: false, toStepIndex: stepIndex });
};

export const setDebugToolBarVisibility = (_: CBStore, visible: boolean) => {
  _.nudgeDebugToolBar.visible = visible;
};

export const setEntityTimer = (
  _: CBStore,
  entity: TriggerableEntity & { trigger: { type: 'after_time' } },
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  const delay =
    entity.trigger.meta.unit === 'minute' ? entity.trigger.meta.value * 60 * 1000 : entity.trigger.meta.value * 1000;

  const timer = setTimeout(() => {
    if (isNudge(entity)) {
      sendDirectedTrigger(_, entity, entity.trigger, overrides, properties);
    } else {
      ChecklistServiceActions.triggerChecklists(_, entity.trigger);
    }
  }, delay);

  _.timedTriggers.set({ id: generateTriggerableEntityId(entity), entity }, timer);
};

export const removeTimedTriggers = (_: CBStore, entityType?: TriggerableEntityType) => {
  for (const [entity, timer] of _.timedTriggers) {
    if (!entityType || entity.id.startsWith(entityType)) {
      clearTimeout(timer);
      _.timedTriggers.delete(entity);
    }
  }
};

export const resetTimedTriggers = (_: CBStore) => {
  const entriesSnapshot = Array.from(_.timedTriggers.keys());

  removeTimedTriggers(_);

  for (const { entity } of entriesSnapshot) {
    setEntityTimer(_, entity);
  }
};

export const setupTimedTriggers = (
  _: CBStore,
  triggerableEntities: Array<TriggerableEntity>,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  removeTimedTriggers(_);

  const delayedTriggerableEntities = triggerableEntities.filter(
    (entity): entity is TriggerableEntity & { trigger: { type: 'after_time' } } => entity.trigger.type === 'after_time',
  );

  for (const entity of delayedTriggerableEntities) {
    setEntityTimer(_, entity, overrides, properties);
  }
};
