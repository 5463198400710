/** @jsx jsx */
/** @jsxFrag */
import React, { CSSProperties, MouseEventHandler, useEffect } from 'react';
import { css, jsx } from '@emotion/core';

import { useStore } from 'shared/util/hooks/useStore';
import { getCommandById } from '@commandbar/commandbar/shared/store/global-selectors';
import VideoPreview from 'shared/components/video/VideoContainer';
import { BookOpen02, Image01, VideoRecorder } from '@commandbar/design-system/icons/react';

import TextInputBlock from './surveys/TextInputBlock';
import RatingInputBlock from './surveys/RatingInputBlock';
import helpdocService from '@commandbar/commandbar/shared/services/services/helpdocService';
import NudgeMachine from '../store/nudgeMachine';

import type { ActorRefFrom } from 'xstate';
import type { ICommandType, INudgeStepType } from '@commandbar/internal/middleware/types';
import TextInputShortBlock from './surveys/TextInputShortBlock';
import ListBlock from './surveys/ListBlock';
import { determineAction } from '../store/selectors';
import { ListDropdownBlock } from './surveys/ListDropdownBlock';
import { parseMarkdown } from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';
import StyledNudgeImage from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeImage';
import StyledNudgeVideo from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeVideo';
import StyledNudgeDocument from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeDocument';
import getPlayer from '@commandbar/commandbar/shared/components/video/players';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useSurveyResponse } from './SurveyResponseProvider';

const getStyles = (): Record<string, CSSProperties> => ({
  image: { width: '100%', borderRadius: '4px', pointerEvents: 'none' },
  helpDoc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '8px',
    height: '175px',
    borderRadius: '4px',
  },
  helpDocPreview: {
    position: 'relative',
    width: '100%',
    height: '175px',
    padding: '16px 16px 0',
    borderRadius: '4px',
    cursor: 'pointer',
    pointerEvents: 'all',
    overflow: 'hidden',
  },
  helpDocTitle: {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '39px',
  },
  ctaWithStepCount: {
    display: 'inline-flex',
    width: 'auto',
    maxWidth: '50%',
  },
});

const EyeButton = ({
  background,
  foreground,
}: {
  background: CSSProperties['fill'];
  foreground: CSSProperties['fill'];
}) => (
  <svg
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="88"
    fill="none"
    viewBox="0 0 88 80"
  >
    <g filter="url(#filter0_d_437_3698)">
      <rect width="56" height="56" x="16" y="12" fill={background} rx="28"></rect>
      <path fill={foreground} d="M44 42.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
      <path
        fill={foreground}
        fillRule="evenodd"
        d="M35.103 39.54a9.38 9.38 0 0117.794-.007c.1.302.1.627 0 .928a9.38 9.38 0 01-17.794.006c-.1-.301-.1-.627 0-.928zm13.272.46a4.375 4.375 0 11-8.75 0 4.375 4.375 0 018.75 0z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_437_3698"
        width="88"
        height="88"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="8"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_437_3698"></feBlend>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_437_3698" result="shape"></feBlend>
      </filter>
    </defs>
  </svg>
);

const getVariantFromLayout = (layout: INudgeStepType['form_factor']['layout']) => {
  switch (layout) {
    case 'vertical':
      return 'top-block';
    case 'horizontal':
      return 'full-height';
    case 'classic':
    default:
      return 'default';
  }
};

interface ContentProps {
  type: INudgeStepType['form_factor']['type'];
  contentBlock: INudgeStepType['content'][number];
  markdownStyles: CSSProperties;
  buttonStyles: CSSProperties;
  service?: ActorRefFrom<typeof NudgeMachine>;
  helpDocStyles?: {
    color: CSSProperties['color'];
    background: CSSProperties['background'];
    mixBlendMode: CSSProperties['mixBlendMode'];
  };
  styleOverrides?: CSSProperties;
  step: INudgeStepType;
  handleContentLinkClick?: MouseEventHandler;
}

const Content = ({
  type,
  contentBlock,
  markdownStyles,
  buttonStyles,
  service,
  helpDocStyles,
  styleOverrides,
  step,
  handleContentLinkClick,
}: ContentProps) => {
  const _ = useStore();
  const styles = getStyles();
  const nudgeServiceSnapshot = service?.getSnapshot()?.context;
  const { isMobileDevice } = useMobileExperience();
  const { setSurveyResponse } = useSurveyResponse();

  const commandId = contentBlock?.meta && 'command' in contentBlock.meta ? contentBlock.meta.command : undefined;
  const orgId = _.organization?.id;

  useEffect(() => {
    setSurveyResponse(nudgeServiceSnapshot?.surveyResponses[step.id] || undefined);
  }, [setSurveyResponse, contentBlock.meta, step.id]);

  const onHelpDocClick = React.useCallback(() => {
    service?.send({
      type: 'ADVANCE',
      button_type: 'help_doc',
      action: determineAction(_, step, {
        button_type: 'help_doc',
      }),
    });
  }, [service]);

  const [command, setCommand] = React.useState<ICommandType | undefined>(undefined);

  React.useEffect(() => {
    setCommand(undefined); // clear any previously-set command
    if (commandId === undefined) return;

    // try to get command from list of commands in Engine
    const commandFromEngine = getCommandById(_, commandId);
    if (commandFromEngine) {
      setCommand(commandFromEngine);
      return;
    }

    // --otherwise-- need to fetch from helpdoc service
    let canceled = false;
    (async () => {
      if (!orgId) return;

      const command = await helpdocService.getHelpdocCommand(_, commandId);
      if (canceled) return;

      if (command) setCommand(command);
    })();
    return () => {
      canceled = true;
    };
  }, [orgId, commandId]);

  switch (contentBlock?.type) {
    case 'markdown': {
      if (!!contentBlock.meta.value && !_.flags?.['release-themes-v2']) {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
            <div
              css={css`
                ol,
                ul,
                li {
                  all: revert;
                }

                ol,
                ul {
                  padding-left: 32px;
                  margin-bottom: 8px;
                }

                img {
                  max-width: 100%;
                }

                p {
                  margin-bottom: 8px;
                }

                p:last-child {
                  margin-bottom: 0;
                }

                pre {
                  overflow: auto;
                }
              `}
              style={markdownStyles}
              dangerouslySetInnerHTML={{ __html: parseMarkdown(contentBlock.meta.value) }}
              onClick={handleContentLinkClick}
            />
          </>
        );
      }
      return null;
    }
    case 'image': {
      const placeholderHeight = (() => {
        if (type === 'popover') {
          return '168px';
        }

        if (type === 'pin') {
          return '168px';
        }

        if (type === 'modal') {
          return '192px';
        }
      })();

      return _.flags?.['release-themes-v2'] ? (
        <StyledNudgeImage
          type={type}
          src={contentBlock.meta.src}
          file_name={contentBlock.meta.file_name}
          variant={getVariantFromLayout(isMobileDevice ? 'classic' : step.form_factor.layout)}
        />
      ) : contentBlock.meta.src ? (
        <img
          data-testid="commandbar-nudge-image"
          src={contentBlock.meta.src}
          alt={contentBlock.meta.file_name}
          style={{ ...styles.image, ...styleOverrides }}
        />
      ) : (
        <div
          data-testid="commandbar-nudge-image-placeholder-v1"
          style={{
            height: placeholderHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <Image01
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );
    }
    case 'video': {
      if (_.flags?.['release-themes-v2']) {
        const src =
          contentBlock.meta.type === 'command' && command?.template.type === 'video'
            ? command.template.value
            : contentBlock.meta.type === 'url'
            ? contentBlock.meta.src
            : '';
        return (
          <StyledNudgeVideo
            type={type}
            src={src}
            getPlayer={getPlayer}
            testMode={_.testMode}
            variant={getVariantFromLayout(isMobileDevice ? 'classic' : step.form_factor.layout)}
          />
        );
      }

      const placeholder = (
        <div
          data-testid="commandbar-nudge-video-placeholder"
          style={{
            height: type === 'modal' ? '207px' : '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8px',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <VideoRecorder
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );

      if (contentBlock.meta.type === 'command') {
        if (command?.template.type === 'video') {
          return (
            <VideoPreview
              source={command.template.value}
              containerStyles={{ height: 'auto', marginTop: '8px', padding: 0 }}
              autoPlay={false}
            />
          );
        }
        return placeholder;
      }
      if (contentBlock.meta.type === 'url') {
        return contentBlock.meta.src.length > 0 ? (
          <VideoPreview
            source={contentBlock.meta.src}
            containerStyles={{ height: 'auto', marginTop: '8px', padding: 0 }}
            autoPlay={false}
          />
        ) : (
          placeholder
        );
      }

      return null;
    }
    case 'help_doc_command': {
      if (_.flags?.['release-themes-v2']) {
        return (
          <StyledNudgeDocument
            type={type}
            document={command}
            onClick={onHelpDocClick}
            variant={getVariantFromLayout(isMobileDevice ? 'classic' : step.form_factor.layout)}
          />
        );
      }

      if (command && command.is_live) {
        return (
          <button
            type="button"
            data-testid="commandbar-nudge-help-doc"
            style={{
              // unstyle button
              textAlign: 'left',
              background: 'none',
              border: 'none',
              padding: 0,
              ...styles.helpDoc,
              ...styleOverrides,
            }}
            onClick={onHelpDocClick}
          >
            {!!command?.thumbnail ? (
              <div
                style={{
                  ...styles.helpDocPreview,
                  padding: 0,
                  overflow: 'hidden',
                  width: '100%',
                  backgroundImage: command?.thumbnail?.src ? `url(${command.thumbnail.src})` : '',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            ) : (
              <div
                style={{
                  ...styles.helpDocPreview,
                  background: helpDocStyles?.background,
                }}
              >
                <span
                  className="commandbar-help-doc-title"
                  style={{
                    ...styles.helpDocTitle,
                    color: helpDocStyles?.color,
                    mixBlendMode: helpDocStyles?.mixBlendMode,
                  }}
                >
                  {command?.text}
                </span>
                <EyeButton background={buttonStyles.backgroundColor} foreground={buttonStyles.color} />
              </div>
            )}
          </button>
        );
      }
      return (
        <div
          style={{
            height: styles.helpDoc.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <BookOpen02
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );
    }
    case 'survey_text': {
      return <TextInputBlock placeholder={contentBlock.meta.prompt} />;
    }
    case 'survey_text_short': {
      return <TextInputShortBlock placeholder={contentBlock.meta.prompt} />;
    }
    case 'survey_rating': {
      return (
        <React.Fragment>
          <RatingInputBlock ratingBlock={contentBlock.meta} />
        </React.Fragment>
      );
    }
    case 'survey_list': {
      if (contentBlock.meta.display_type === 'dropdown') {
        return <ListDropdownBlock options={contentBlock.meta.options} />;
      }
      return (
        <ListBlock options={contentBlock.meta.options} isMultiSelect={contentBlock.meta.list_type === 'multiple'} />
      );
    }
    default:
      return null;
  }
};

export default Content;
